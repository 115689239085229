const cartReturnItems = `
items {
  id
  uid
  position
  quantity
  associated_uid
  image_url
  full_spec
  prices {
    price {
      value
    }
    price_including_tax {
			value
		}
  }
  product {
    sku
    name
    uid
    category_level_1
    category_level_2
    category_level_3
    __typename
    disallow_in_cycletowork
    banned_shipping_countries
    image {
      url
    }
    price_range {
      minimum_price {
        regular_price {
          value
          currency
        },
        final_price {
          value
          currency
        }
      }
    }
  }
  ... on BundleCartItem {
    bundle_bike_options {
      label
      values {
        label
        price
        quantity
        frame_color
        configurable_label
        configurable_option
      }
    }
  }
  ... on ConfigurableCartItem {
    configurable_options {
      option_label
      value_label
    }
  }
}
`;

const cartPrices = `
 prices {
  discounts {
    amount {
      value
    }
  }
  grand_total {
    value
  }
  default_shipping_rate {
		 label
		 value
	}
  subtotal_excluding_tax {
    value
  }
  subtotal_including_tax {
    value
  }
  applied_taxes {
    label
    amount {
      value
    }
  }
}
`;

export function fetchCart(cartId: string) {
  return `
    query {
      cart(cart_id: "${cartId}") {
        ${cartPrices}
        applied_coupons {
          code
        }
        ${cartReturnItems}
      }
    }
  `;
}

export function updateCart(
  cartId: string,
  cartItemUid: string,
  quantity: number
) {
  return `
    mutation {
      updateCartItems(
        input: {
          cart_id: "${cartId}"
          cart_items: [{
            cart_item_uid: "${cartItemUid}"
            quantity: ${quantity}
          }]
        }
      ) {
        cart {
          ${cartPrices}
          applied_coupons {
            code
          }
          ${cartReturnItems}
        }
      }
    }
  `;
}

export function addSimpleMutation(cartId: string, cartItems: any) {
  return `
    mutation {
      addProductsToCart(
      cartId: "${cartId}"
      cartItems: ${cartItems}
    ) {
      cart {
        ${cartPrices}
        applied_coupons {
          code
        }
        ${cartReturnItems}
      }
      user_errors {
        code
        message
      }
    }
  }
`;
}

export function addConfigurableMutation(cartId: string, cartItems: any) {
  return `
    mutation {
      addConfigurableProductsToCart(
        input: {
          cart_id: "${cartId}"
          cart_items: ${cartItems}
        }
      ) {
        cart {
          ${cartPrices}
          applied_coupons {
            code
          }
          ${cartReturnItems}
        }
      }
    }
  `;
}

export function removeMutation(cartId: string, cartItemId: string) {
  return `
    mutation {
      removeItemFromCart(
        input: {
          cart_id: "${cartId}",
          cart_item_id: "${cartItemId}"
        }
      ) {
        cart {
          ${cartPrices}
          applied_coupons {
            code
          }
          ${cartReturnItems}
        }
      }
    }
  `;
}

export function addBikeBuilderBikeMutation(cartId: string, cartItems: any) {
  return `
    mutation {
      addBikeToCart(
        input: {
          cart_id: "${cartId}",
          cart_items: ${cartItems}
        }
      ) {
        cart {
          ${cartPrices}
          applied_coupons {
            code
          }
          ${cartReturnItems}
        }
      }
    }
  `;
}

export function applyCouponCodeMutation(cartId: string, couponCode: string) {
  return `
    mutation {
      applyCouponToCart(
        input: {
          cart_id: "${cartId}",
          coupon_code: "${couponCode}"
        }
      ) {
        cart {
          ${cartPrices}
          applied_coupons {
            code
          }
          ${cartReturnItems}
        }
      }
    }
  `;
}

export function removeCouponCodeMutation(cartId: string) {
  return `
    mutation {
      removeCouponFromCart(
        input: {
          cart_id: "${cartId}"
        }
      ) {
        cart {
          ${cartPrices}
          applied_coupons {
            code
          }
          ${cartReturnItems}
        }
      }
    }
  `;
}
